import logo from './logo.svg';
import './App.css';
import 'bootstrap/dist/css/bootstrap.css';
import ModalPage from './ModalPage.tsx';
import TablePage from './TablePage.tsx';
import ReactDOM from "react-dom/client";
import {
  BrowserRouter,
  Routes,
  Route,
} from "react-router-dom";

// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getDatabase  } from 'firebase/database';
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries
// Your web app's Firebase configuration

// Herramental
// const firebaseConfig = {
//   apiKey: "AIzaSyBlIPsIUejtmB6_QdoiXm45xK4ureIBS1w",
//   authDomain: "cotizaciones-496d7.firebaseapp.com",
//   databaseURL: "https://cotizaciones-496d7-default-rtdb.firebaseio.com",
//   projectId: "cotizaciones-496d7",
//   storageBucket: "cotizaciones-496d7.appspot.com",
//   messagingSenderId: "889498137665",
//   appId: "1:889498137665:web:d688283c2a4b7444ca02c0"
// };

// Metalinspec
const firebaseConfig = {
  apiKey:"AIzaSyBxcZW6qnJ0HxMt52ohOsuFw-hnX35YXo8",
  authDomain:"vtex-d78ab.firebaseapp.com",
  databaseURL:"https://vtex-d78ab-default-rtdb.firebaseio.com",
  projectId:"vtex-d78ab",
  storageBucket:"vtex-d78ab.appspot.com",
  messagingSenderId:"589500109108",
  appId:"1:589500109108:web:c965e3b4551d8cc9cd5561"
}

// Dev
// const firebaseConfig = {
//   apiKey: "AIzaSyANDZGzl6sywJCmi0uv0KOpKhxsdYHW0SI",
//   authDomain: "proyectos-40620.firebaseapp.com",
//   databaseURL: "https://proyectos-40620.firebaseio.com",
//   projectId: "proyectos-40620",
//   storageBucket: "proyectos-40620.appspot.com",
//   messagingSenderId: "971995765416",
//   appId: "1:971995765416:web:c312454605169782a2809d"
// };

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const database = getDatabase(app);

function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <Routes>
          <Route path="/upload" element={<ModalPage />}>
            <Route path=":id" element={<ModalPage /> } >
              <Route path=":OCT" element={<ModalPage /> } />
            </Route>
          </Route>
          <Route path="/table" element={<TablePage />}>
            <Route path=":dlType" element={<TablePage /> }>
              <Route path=":id" element={<TablePage /> } />
            </Route>
          </Route>
        </Routes>
      </BrowserRouter>

      {/*
      <ModalPage></ModalPage>
      <br></br>
      <TablePage></TablePage>
      
      <ul className="nav nav-tabs">
        <li className="nav-item">
          <button  className="nav-link" data-bs-toggle="tab" data-bs-target="#modal">Modal</button>
        </li>
        <li className="nav-item">
          <button  className="nav-link" data-bs-toggle="tab" data-bs-target="#tabla">Tabla</button>
        </li>
      </ul>

      <div className="tab-content" >
        <div className="tab-pane fade" id="modal" role="tabpanel">
          <ModalPage></ModalPage>
        </div>
        <div className="tab-pane fade" id="tabla" role="tabpanel">
          <TablePage></TablePage>
        </div>
      </div>
      */}
    </div>
  );
}

export default App;
