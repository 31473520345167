import React, {useEffect, useRef, useState}  from 'react'
import { getDatabase, ref, onValue, get, child} from "firebase/database";
import Table from 'react-bootstrap/Table';
import { useParams } from 'react-router-dom';

const TablePage = ({}) =>{
    const [tableData, setTableData] = useState({});
    const db = getDatabase();
    const refIsita = ref(db, 'isita');
    const tBody = useRef(null);
    const urlParams = useParams();
    let eventData = {};
    
    useEffect(() => {
        getData();
        //console.log('urlParams', urlParams);
        if(urlParams['dlType'] != null && urlParams['id'] != null){
            downloadDocument(urlParams['dlType'], urlParams['id']);
        }
    }, []);

    function downloadDocument(type, id){
        //let finalURL = "https://aspelnodeserver.herokuapp.com/"+encodeURIComponent(type)+"/"+encodeURIComponent(id);
        let finalURL = "/api/"+encodeURIComponent(type)+"/"+encodeURIComponent(id);
        console.log('finalURL', finalURL);
        fetch(finalURL)
            .then(response => {
                return response.json();
            })
            .then(data => {
                if(data.url != ""){
                    window.location = data.url;
                }                
            })
            .catch(error => {
                console.log('Error al abrir la url', error);
            });
    }

    function getData(){
        get(child(refIsita, `archivos`)).then((snapshot) => {
            if (snapshot.exists()) {
                //console.log(snapshot.val());
                eventData = snapshot.val();
                //console.log('eventData',eventData);
                setTableData(tableData => eventData);
                /*
                Object.keys(eventData).forEach(eventKey => {
                    
                });
                */
            } else {
                console.log("No data available");
            }
        }).catch((error) => {
            console.error(error);
        });
    } 

    return(        
        <>
            <br></br>
            <Table striped bordered hover>
                <thead>
                    <tr>
                        <th style={{ width:'25%'} }>PDF</th>
                        <th style={{ width:'25%'} }>OC</th>
                        <th style={{ width:'25%'} }>Cotización</th>
                        <th style={{ width:'25%'} }>Numero VTEX</th>
                    </tr>
                </thead>
                <tbody ref={tBody}>
                    {Object.keys(tableData).map((item, index) => (
                        <tr key={index}>
                            <td><a href={tableData[item].url} target='_blank'>{tableData[item].fileName}</a></td>
                            <td><a href={`upload/${encodeURIComponent(tableData[item].oc)}`}>{tableData[item].oc}</a></td>
                            <td><a href={`upload/${tableData[item].cotizacion}/OCT`}>{tableData[item].cotizacion}</a></td>
                            <td>{tableData[item].numvtex}</td>
                        </tr>
                    ))}
                </tbody>
            </Table>
        </>
    )
}
export default TablePage