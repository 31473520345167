import React, { useEffect, useState } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Alert from 'react-bootstrap/Alert';
import { getStorage, ref, uploadBytes, getDownloadURL, uploadBytesResumable } from "firebase/storage";
import { getDatabase, ref as dbref, child, push, set, get, update  } from "firebase/database";
import { useParams } from 'react-router-dom';

const ModalPage = ({}) =>{
    const [show, setShow] = useState(false);
    const [showSubmit, setShowSubmit] = useState(true);
    const [showAlert, setShowAlert] = useState(false);
    const [showUploaded, setShowUploaded] = useState(false);
    const [inputOC, setInputOC] = useState("");
    const [inputOCT, setInputOCT] = useState("");
    const [inputNumVTEX, setInputNumVTEX] = useState("");
    const [referenceKey, setReferenceKey] = useState("");
    const regex = /^[0-9a-zA-Z(\-)]+$/;
    const handleClose = () => {setShow(false)};
    const handleShow = () => setShow(true);
    const handleShowSubmit = () => setShowSubmit(true);
    const handleCloseSubmit = () => setShowSubmit(false);
    const handleShowAlert = () => setShowAlert(true);
    const handleCloseAlert = () => setShowAlert(false);
    const handleShowUploaded = () => setShowUploaded(true);
    const handleCloseUploaded = () => setShowUploaded(false);
    const storage = getStorage();
    const db = getDatabase();
    const urlParams = useParams();
    let hasFile = false;
    const [readOnlyOC, setReadOnlyOC] = useState(false);

    useEffect(() => {
        console.log('urlParams', urlParams)
        //Por OC
        if(urlParams['id'] != null){
            if(urlParams['OCT'] != null){
                fetch(`/api/OCDatos/${urlParams['id']}`)
                .then(response => {
                    return response.json();
                })
                .then(data => {
                    if(data.oc.numCotizacion != ""){
                        setInputOC(data.oc.numOC);
                        setInputOCT(data.oc.numCotizacion);
                        setInputNumVTEX(data.oc.numPedido);
                        hasFile = true;
                        setReadOnlyOC(true);
                        setReferenceKey(data.oc.key)
                    }else{
                        let stringParam = urlParams['id'] as string;
                        setInputOCT(stringParam);
                    }
                })
                .catch(error => {
                    console.log('Error al abrir la url', error);
                });
            }else{
                fetch(`/api/get/${encodeURIComponent(urlParams['id'])}`)
                .then(response => {
                    return response.json();
                })
                .then(data => {
                    if(data.result != "error"){
                        setInputOC(data.data.numOC);
                        setInputOCT(data.data.numCotizacion);
                        setInputNumVTEX(data.data.numPedido);
                        hasFile = true;
                        setReadOnlyOC(true);
                        setReferenceKey(data.data.key)
                    }else{
                        let stringParam = urlParams['id'] as string;
                        setInputOC(stringParam);
                    }
                })
                .catch(error => {
                    console.log('Error al abrir la url', error);
                });
            }
        }
    }, []);

    function handleSubmit(event){
        event.preventDefault();
        console.log('submit');

        handleCloseAlert();
        handleCloseUploaded();
        let formData = new FormData(event.target);
        let object = {};
        formData.forEach((value, key) => object[key] = value);
        //console.log('object',object);
        let file = event.target[0]?.files[0];
        //console.log('file',file);
        //console.log('object[file]',object['file']);
        console.log('hasFile', hasFile);
        if(file !== undefined && object['orden-compra'].trim() != ""){
            handleCloseSubmit();

            const newKey = push(child(dbref(db,'isita'), 'archivos')).key;
            //let storageRef = ref(storage, `files/${file.name}`);
            let storageRef = ref(storage, `files/${newKey}/${file.name}`);
            const uploadTask = uploadBytesResumable(storageRef, file);

            uploadTask.on("state_changed",
                (snapshot) => {
                    const progress = Math.round((snapshot.bytesTransferred / snapshot.totalBytes) * 100);
                    console.log('progress',progress);
                },
                (error) => {
                    console.log('uploadError',error);
                },
                () => {
                    getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
                        console.log('downloadURL',downloadURL);
                        const dbData = {
                            fileName: file.name,
                            oc: object['orden-compra'].trim(),
                            cotizacion: object['cotizacion'].trim(),
                            numvtex: object['numpedidovtex'].trim(),
                            url: downloadURL
                        }

                        //const newKey = object['orden-compra'].trim();
                        
                        set(dbref(db, 'isita/archivos/' + newKey), dbData)
                        .then(() => {
                            handleClose();
                            handleShowSubmit();
                            handleShowUploaded();
                            //window.location.reload();
                            window.history.back();
                        })
                        .catch((error) => {
                            console.log('dbError',error);
                            handleClose();
                        });
                    });
                }
            );
        }else if(readOnlyOC && object['orden-compra'].trim() != "" && referenceKey != ""){
            handleCloseSubmit();
            
            const dbData = {
                oc: object['orden-compra'].trim(),
                cotizacion: object['cotizacion'].trim(),
                numvtex: object['numpedidovtex'].trim()
            }

            //const newKey = object['orden-compra'].trim();
            const newKey = referenceKey;
            
            update(dbref(db, 'isita/archivos/' + newKey), dbData)
            .then(() => {
                handleClose();
                handleShowSubmit();
                handleShowUploaded();
                window.history.back();
            })
            .catch((error) => {
                console.log('dbError',error);
                handleClose();
            });
        }else{
            console.log('Faltan datos');
            handleShowAlert();
        }
        /*
        uploadBytes(storageRef, file).then((snapshot) => {
            console.log('Uploaded a blob or file!');
        });
        */
    }

    function filterInputOC(event){
        const value = event.target.value.trim();
        setInputOC(value);
    }

    function filterInputOCT(event){
        const value = event.target.value.trim();
        if (value.match(regex) || value === "") {
            setInputOCT(value);
        }
    }
    function filterInputNumVTEX(event){
        const value = event.target.value.trim();
        if (value.match(regex) || value === "") {
            setInputNumVTEX(value);
        }
    }

    return(        
        <>
            <br></br>
            {/*
            <Button variant="danger" onClick={handleShow}>
                Subir OC
            </Button>
            */}

            <Modal show={true}>
                <Modal.Header>
                    <Modal.Title></Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {showAlert ? (
                        <Alert variant='danger'>
                            El archivo y la orden de Compra son requeridos
                        </Alert>
                    ): (
                        <></>
                    )}
                    {showUploaded ? (
                        <Alert variant='success'>
                            Archivo subido
                        </Alert>
                    ): (
                        <></>
                    )}
                    <form name='eventForm' className='eventForm' onSubmit={handleSubmit}>
                        <div>Selecciona el archivo de la Orden de Compra a subir</div><br></br>
                        <input type="file" name='pdf' id='pdf-file' className='form-control' accept='.pdf'/><br></br>
                        <input type="text" name='orden-compra' id='orden-compra' className='form-control'
                            placeholder='Numero de Orden de Compra' onChange={filterInputOC} value={inputOC}
                            readOnly={readOnlyOC}
                        /><br></br>
                        <input type="text" name='cotizacion' id='cotizacion' className='form-control' placeholder='Numero de Cotizacion del pedido' onChange={filterInputOCT} value={inputOCT} /><br></br>
                        <input type="text" name='numpedidovtex' id='numpedidovtex' className='form-control' placeholder='Numero de Pedido VTEX' onChange={filterInputNumVTEX} value={inputNumVTEX} /><br></br>
                        {showSubmit ? (
                            <Button variant="danger" type="submit">
                                Subir PDF
                            </Button>
                        ): (
                            <></>
                        )}
                    </form>
                </Modal.Body>
                {/*
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        Close
                    </Button>
                    <Button variant="primary" onClick={handleClose}>
                        Save Changes
                    </Button>
                </Modal.Footer>
                */}
            </Modal>
        </>
    );
}
export default ModalPage